/* src/styles/Home.css */

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Main background for the home container */
.home-container {
  background-image: url('../bg.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

/* Profile image styling */
.herop-image img {
  width: 350px; /* Smaller size for a more compact look */
  height: 350px;
  border-radius: 50%; /* Makes the image a perfect circle */
  border: 3px solid rgb(42, 105, 231); /* Consistent blue border */
  object-fit: cover; /* Ensures the image fills the circle without distortion */
  margin-bottom: 20px;
}

.herop-image h2 {
  font-size: 1.9em; /* Adjust the size as needed */
  color: #cb5a14; /* Blue color to match the links */
  margin-top: 0px;
  font-weight: bold;
  text-align: center;
  font-family: 'Merriweather', serif;
  transition: color 0.3s;
}
/* CSS for typing effect container */
.typing-text {
  font-size: 1.8em;
  font-weight: bold;
  color: #cb5a14;
  font-family: 'Merriweather', serif;
  margin: 20px 0;
  width: 90%; /* Prevents overflow */
  max-width: 600px; /* Max width for larger screens */
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}


@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  50% { border-color: transparent; }
}

/* Download buttons container */
.download-buttons {
  display: flex;
  gap: 15px;
  margin: 20px 0;
}

.download-btn {
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: #1E90FF;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background 0.3s;
}

.download-btn:hover {
  background-color: #cb5a14; /* Change to orange on hover */
}

/* Experience section styling */
.experience-section {
  margin-top: 30px;
  text-align: left;
  max-width: 600px;
}

.experience-section h2 {
  font-size: 1.5em;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
}

.experience-section ul {
  list-style-type: none;
  padding: 0;
  color: #f5f5f5;
}

.experience-section li {
  font-size: 1.1em;
  margin: 10px 0;
}

/* src/styles/Home.css */

.social-links {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.social-btn {
  font-size: 1.8em;
  color: #cb5a14;
  transition: color 0.3s;
}

.social-btn:hover {
  color: #1E90FF; /* Blue color on hover */
}

