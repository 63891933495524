/* src/styles/Project.css */

body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Main background for the project container */
  .project-container {
    background-image: url('../bg.jpg'); /* Background image for Project page */
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    color: #333;
  }
  
  /* Styling for the project title */
  .project-title {
    font-size: 2.5em;
    font-weight: bold;
    color: #1E90FF;
    margin: 40px 0 20px; /* Top and bottom margin for spacing */
    text-align: center;
    font-family: 'Merriweather', serif;
  }
  
  /* Project grid styling */
  .project-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 40px;
    max-width: 1200px;
    width: 100%;
  }
  
  /* Project card styling */
  .project-card {
    position: relative;
    cursor: pointer;
    text-align: center;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 10px;
  }
  
  .project-card img {
    width: 100%;
    height: auto;
    border-radius: 10px 10px 0 0;
    transition: transform 0.3s ease;
  }

  .project-card h3 {
    margin: 10px 0;
    font-size: 1.2em;
    color: #cb5a14; /* Change this to any color you want */
    font-family: 'Merriweather', serif; /* Optional: font styling */
  }
  
  /* Arrow button styling */
  .arrow-button {
    background: none;
    border: none;
    color: #cb5a14;
    font-size: 1.5em;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .arrow-button:hover {
    color: #1E90FF;
  }
  
  /* Project details styling for dropdown */
  .project-details {
    background: rgba(255, 255, 255, 0.15);
    padding: 15px;
    border-radius: 0 0 10px 10px;
    color: #cb5a14;
    animation: dropdown 0.3s ease-out;
  }
  
  /* Dropdown animation */
  @keyframes dropdown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .project-details p {
    font-size: 1em;
    color: black;
    margin: 10px 0;
  }
  
  .project-details a {
    color: #1E90FF;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s;
  }
  
  .project-details a:hover {
    color: #cb5a14;
  }
  