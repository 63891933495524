/* src/App.css */

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  width: 100%;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  background-color: #131116; /* Black background for the navbar */
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.navbar-left, .navbar-right, .navbar-center {
  display: flex;
  align-items: center;
}

.logo {
  width: 50px;
  height: 50px;
  margin-left: 20px;
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image scales well within the circle */
}

.name {
  font-size: 2em;
  font-weight: bold;
  font-family: 'Merriweather', serif;
  color: #cb5a14;
  margin: 0;
}

.navbar-right {
  gap: 40px;
  padding-right: 20px;
}

.nav-link {
  color:rgb(42, 105, 231); /* White color for links */
  text-decoration: none;
  font-size: 1.5em;
  transition: color 0.3s;
  font-family: 'Merriweather', serif;
}

.nav-link:hover {
  color: #cb5a14; /* Gold color on hover */
}

.active-link {
  color: #ffd700; /* Gold color for active link */
  font-weight: bold; /* Make active link bold */
}

/* Full-width content area below the navbar */
.content {
  padding-top: 120px; /* Offset for the fixed navbar */
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
}
