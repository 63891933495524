/* src/styles/About.css */

body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Main background for the about container */
  .about-container {
    background-image: url('../bgh.jpg'); /* Different background for About page */
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    padding: 0;
    margin: 0;
  }
  
  /* Glassy container styling for hero section (for h1 and p together) */
  .hero .blur-container {
    background: rgba(255, 255, 255, 0.045); /* Light translucent background */
    padding: 20px;
    border-radius: 10px;
    backdrop-filter: blur(10px); /* Frosted glass effect */
    flex: 1; /* Allow container to take available space */
    margin: 0; /* Remove margin to bring closer to image */
  }
  
  /* Hero section general styling */
  .hero {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 0 20px; /* Minimize padding for closer alignment */
    gap: 10px; /* Minimal gap between container and image */
    color: #fff;
  }
  
  /* Specific h1 styling in hero section */
  .hero-content h1 {
    font-size: 2.75em;
    font-weight: bold;
    color: #cb5a14;
    align-self: center;
    text-align: center;
    font-family: 'Merriweather', serif;
  }
  
  /* Specific h2 styling in hero section */
  .hero-content h2 {
    font-size: 1.4em;
    font-weight: bold;
    color: rgb(42, 105, 231);
    align-self: center;
    text-align: center;
    font-family: 'Merriweather', serif;
  }
  
  /* Specific p styling in hero section */
  .hero-content p {
    font-size: 1.1em;
    color: #f5f5f5;
    line-height: 1.5;
    font-family: 'Merriweather', serif;
  }
  
  /* Ensure hero image is tightly aligned to the right of text container */
  .hero-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .hero-image img {
    width: 490px; /* Adjust width to fit better */
    height: auto;
    border-radius: 50%; /* Makes the image circular */
    border: 5px solid rgb(42, 105, 231); /* Blue border */
    object-fit: cover; /* Ensures the image scales well within the circle */
    margin-left: 0;
  }
  